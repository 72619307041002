import React from 'react'
import { Link } from 'gatsby'

import { Article } from '@components'
import SEO from '../components/SEO'
import Section from '../components/Section'

export default function Page() {
  return (
    <>
      <SEO title="Vilkår for kjøp og bruk" />
      <Section type="grid">
        <Article
          title="Vilkår for kjøp og bruk"
          preamble="Disse vilkårene gjelder fra 1. oktober 2023. Foodsteps AS kan fritt endre vilkårene for bruk, kjøp og personvern. Oppdatert versjon er alltid tilgjengelig på denne siden.
          Når du registrerer bruker hos Foodsteps godtar du samtidig disse vilkårene."
          toc
        >
          <Article.Section title="Bruksvilkår" id="bruksvilkar">
            <Article.Text>
            Foodsteps er en digital nettjeneste som gjør det enklere å lage god mat, planlegge middager, spare penger og skape mindre matsvinn. Den lar også brukere dele sin egen matglede i den sosiale strømmen (Foodsteps Social Feed), og lar brukere oppdage og interagere med andre matglade personer i hele Norge.
            </Article.Text>
            <Article.Text>
            For å kunne bruke Foodsteps må du registrere deg som bruker. For å registrere bruker må du være minst 16 år.

            </Article.Text>
            <Article.Text>
            Det er kostnadsfritt å bruke Foodsteps, men enkelte funksjoner vil kun være tilgjengelig med betalt abonnement, eller med tilgang gitt via gavekort/rabattkode. Abonnementer, gavekort og rabattkoder er personlige, og kan ikke brukes av eller overføres til andre.

            </Article.Text>
            <Article.Text>
            Du kan når som helst slette din brukerprofil. Når du sletter din brukerprofil, vil også dine lagrede oppskrifter og menyer, samt egne publiserte bilder og kommentarer slettes fra tjenesten.
            </Article.Text>
            <Article.Text>
            Når du bruker våre tjenester vil Foodsteps AS behandle personopplysningene om deg. Les{' '}
              <Link to="/personvern">personvernerklæringen</Link>vår for å se hvilke opplysninger vi lagrer og hvordan vi behandler dem.
            </Article.Text>
            <Article.Text>
            Foodsteps skal utelukkende brukes i private sammenhenger. Unntak fra dette er ved skriftlig avtale. Innholdet på nettsiden og i appen eies av Foodsteps AS. Det er ikke lov å kopiere noen form for innhold og oppskrifter fra Foodsteps uten vår skriftlige tillatelse.
            </Article.Text>
            <Article.Text>
            Dersom du ønsker å komme i kontakt med oss, send oss en e-post på{' '}
              <a
                href="mailto:post@foodsteps.no"
                target="_blank"
                rel="noopener noreferrer"
              >
                post@foodsteps.no
              </a>{' '}
            </Article.Text>
            <Article.Text>
            Når du godtar våre brukervilkår godtar du også at du aldri skal bruke tjenestene våre på noen måte som bryter lov, forskrift eller myndighetspålegg.

            </Article.Text>
            <Article.SubTitle type="h3">Foodsteps Social Feed</Article.SubTitle>
            <Article.Text>
            Foodsteps’ sosiale strøm, Foodsteps Social Feed, er et verktøy som har som formål å la brukere oppdage andre matglade brukere, og dele av sin matglede og inspirasjon med Foodsteps-samfunnet, ved å dele sine egne bilder av mat de har laget og/eller spist. Her kan man velge å følge andre brukere, og man har mulighet til å bygge en gruppe av egne følgere.
            </Article.Text>
            <Article.Text>
            Det er kun tillatt å dele bilder relatert til egne mat- og drikkeopplevelser, og som man selv har rettslig tillatelse til å dele. Eksempler på tillatt innhold er bilder av matretter eller prosessbilder av mat man selv har tilberedt, bilder man selv har tatt av retter man har blitt servert på restaurant eller i andre private sammenhenger, bilder av alkoholfri eller alkoholholdig drikke man har kjøpt, laget eller blitt servert som privatperson, sosiale sammenhenger der mat og/eller drikke står sentralt.

            </Article.Text>
            <Article.Text>
            Det er ikke tillatt å benytte seg av Foodsteps Social Feed til kommersielle formål, herunder å dele bilder av og informasjon om produkter man selv produserer og/eller selger, eller som man reklamerer for på vegne av en tredjepart. Unntak fra denne regelen er ved kommersielt samarbeid med Foodsteps AS.

            </Article.Text>
            <Article.Text>
            Det er ikke tillatt å bruke Foodsteps Social Feed til noen form for konkurranser, uten skriftlig avtale med Foodsteps AS.

            </Article.Text>
            <Article.Text>
            Det er ikke tillatt å dele spam eller bilder med innhold som beskriver, viser eller oppfordrer til ulovlig aktivitet. Det er ikke tillatt å dele bilder med rasistisk, seksuelt eller voldelig innhold. Det er ikke tillatt å dele bilder av tydelig religiøs eller politisk karakter.
            </Article.Text>
            <Article.Text>
            Dersom Foodsteps mener at en bruker har delt innhold som bryter med disse retningslinjene, vil de være i sin fulle rett til å slette innholdet fra plattformen. Dersom en bruker begår gjentatte brudd på retningslinjene har Foodsteps rett til å midlertidig eller permanent blokkere og utestenge brukeren.
            </Article.Text>
            <Article.Text>
            Ved å dele bilder på Foodsteps Social Feed, samtykker du til at Foodsteps kan benytte seg av ditt bilde i kommersiell sammenheng. Foodsteps har ikke rett til å selge bildene til tredjepart, eller benytte dine bilder i reklame for en tredjepart.
            </Article.Text>
            <Article.Text>
            Foodsteps Social Feed lar brukere kommentere på og “like” innhold fra andre brukere. Foodsteps har full rett til å slette kommentarer som oppfattes som spam, som ikke holder seg til tema, eller som inneholder sjikane, trusler eller annet ulovlig innhold. Ser Foodsteps at en bruker ved gjentatte anledninger driver med sjikane, trusler eller annen ulovlig aktivitet, eller aktivitet som ikke angår temaet, i kommentarfelt, har vi rett til å midlertidig eller permanent blokkere og utestenge brukeren.

            </Article.Text>
            <Article.SubTitle type="h3">Resteverktøy, budsjettverktøy og handleliste</Article.SubTitle>
            <Article.Text>
            Foodsteps’ resteverktøy er under utvikling og er ment for å gi brukeren tips til bruk av råvarer og rester man har tilgjengelig. Forslagene man får er kun veiledende, og kan inneholde ingredienser man ikke har skrevet inn. Foodsteps er ikke ansvarlig dersom oppskriftsforslagene inneholder ingredienser man ikke har, eller om det ikke er fullt samsvar mellom brukers oppgitte mengde og mengde beskrevet i oppskriften, eller ved andre unøyaktigheter.
            </Article.Text>
            <Article.Text>
            Foodsteps’ budsjettverktøy er under utvikling og er ment for å hjelpe brukere å sette opp en meny med middagsretter basert på budsjett, antall dager og størrelse på husholdning. Foodsteps er ikke ansvarlig for eventuelle avvik mellom priser oppgitt i appen og faktiske priser i butikk.
            </Article.Text>
            <Article.Text>
            Prisene oppgitt i Foodsteps’ budsjettverktøy og handleliste er veiledende og oppdateres minst én gang i uken, for å være mest mulig presise. Prisene hentes eksternt via API-et til tjenesten Kassalapp, og baserer seg på et gjennomsnitt av pris i flere butikkjeder. For informasjon om hvordan prisene innhentes og oppdateres, se på nettsidene til kassal.app(Clickable link) - NB! lenke til ekstern side.
Hverken Foodsteps eller Kassalapp har noe rettslig ansvar ved avvik mellom priser oppgitt i appen og faktiske priser i butikk.

            </Article.Text>

          </Article.Section>

          <Article.Section title="Kjøpsvilkår" id="kjopsvilkar">
            <Article.Text>
            Du må være over 18 år for å kjøpe tilgang til Foodsteps.
            </Article.Text>
            <Article.Text>
            Du kan bruke innholdet med en gang du har lastet ned og logget inn. Normalt gjelder for forbrukerkjøp en 14 dagers angrerett. Foodsteps AS tilbyr syv (7) dager gratis premium tilgang ved førstegangsregistrering. I gratisperioden står du fritt til å si opp uten å bli trukket noe beløp.
            </Article.Text>
            <Article.Text>
            Foodsteps er en tjeneste under utvikling. Foodsteps AS kan fritt justere innholdet, funksjonalitet eller prisen på tjenesten. Ved prisjusteringer får du beskjed om dette 30 dager før endringen trer i kraft.
            </Article.Text>
            <Article.Text>
            Gavekort på Foodsteps kan kun benyttes til bruk av Foodsteps, og kan ikke omsettes i kontanter eller andre varer.
            </Article.Text>
          </Article.Section>

          <Article.Section
            title="Betaling, varighet og oppsigelse"
            id="betaling-varighet-og-oppsigelse"
          >
            <Article.Text>
            Det er gratis å bruke appen Foodsteps, men enkelte funksjoner vil være begrenset til brukere med premium tilgang.
            </Article.Text>
            <>
                  <Article.SubTitle type="h3">Abonnement :</Article.SubTitle>
                  Foodsteps tilbyr abonnement i tre kategorier, månedlig, kvartalsvis og årlig. Abonnementet betales forskuddsvis for perioden og fornyes automatisk ved periodens utløp. Om du avslutter abonnementet, vil du ha tilgang til tjenesten i den perioden du har betalt for. Du får ikke refusjon selv om avtalen sies opp før periodens utløp. Dersom det skulle være en åpenbar mangel ved tjenesten vår, kan du reklamere ved å sende en e-post til oss innen rimelig tid fra mangelen ble oppdaget eller burde vært oppdaget. Dersom en tvist ikke blir løst i minnelighet kan du ta saken til Forbrukerrådet.
                  Abonnementet sies opp via valgt betalingsløsning. Informasjon om abonnement og betalingsløsning finner du ved å gå inn i “Innstillinger” gjennom brukerprofilen, og videre inn i “Mitt abonnement” 

                </>,
                <>
                  <Article.SubTitle type="h3">Gratis prøveperiode :</Article.SubTitle>
                  Dersom du er i en gratis prøveperiode, vil valgt abonnementsperiode belastes ved gratisperiodens utløp. Dersom du sier opp abonnementet mens du er i en gratisperiode, vil du ikke belastes ved gratisperiodens utløp.

                </>,
                <>
                  <Article.SubTitle type="h3">Gavekort :</Article.SubTitle>
                  Foodsteps tilbyr gavekort på 3, 6 og 12 måneder, som selges via vår nettside (https://foodsteps.no). Betalingen trekkes når kjøper bestiller gavekortet. Kjøper får e-post med gavekort og gavekortkode, som innløses på vår nettside. Når gavekortet er innløst, vil abonnementet løpe i oppgitt lengde og deretter avsluttes uten automatisk fornyelse. Dersom det skulle være en mangel ved tjenesten vår, kan du reklamere ved å sende en e-post til oss innen rimelig tid fra mangelen ble oppdaget eller burde vært oppdaget. Dersom en tvist ikke blir løst i minnelighet kan du ta saken til Forbrukerrådet.

                </>,

          </Article.Section>
          <Article.Section title="Betalingmetoder" id="Betalingmetoder">
<>
Abonnement kan kjøpes direkte i app gjennom Apple AppStore og Google PlayStore, eller betales med Vipps ved oppstart via vår nettside (https://foodsteps.no). Abonnement fornyes automatisk til brukeren sier opp.

</>,
<>
Gavekort kan kjøpes med Vipps via vår nettside (https://foodsteps.no). Gavekort er engangskjøp og kan kun benyttes som betalingsmiddel for Foodsteps-abonnement.


</>
          </Article.Section>
        </Article>
      </Section>
    </>
  )
}
