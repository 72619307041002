import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Container } from '@components'

const bem = new BEMHelper('section')

export default function Section({ title, children, type, ...props }) {
  return (
    <Container node="section" {...bem('', type)} {...props}>
      {title && <h2 {...bem('title')}>{title}</h2>}
      {children}
    </Container>
  )
}

Section.propTypes = {
  title: T.string,
  children: T.any,
  type: T.oneOfType([T.string, T.array]),
}
